@media (min-width: 600px){
  body{
    background: white;
  }
  
  .formContainer{
    height:100vh;
    width:100%;
    display:flex;
    align-items: center;/*縦に対して中央に*/
    justify-content: center;/*横に対して中央*/
  
  }
  
  .formContainer form{
    background-color: white;
    width:70%;
    max-width: 450px;
    padding: 30px;
    border-radius: 10px;/*角を丸める*/
    border: 1px solid #dfdfdf; /*枠の色*/
    box-shadow: -4px 8px 19px 5px rgba(0, 0, 0, 0.8);
  }
  
  .uiForm{
    display: flex;
    flex-direction: column;/*縦並び*/
    align-items: center;
    justify-content: space-evenly;
    height: 400px;
  }
  
  h1{
    text-align: center;
  }
  
  .formField {
    display:flex;
    flex-direction: column;
    width: 100%;
  }
  
  .formField input{
  border: 1px solid gray;
  padding: 15px;
  border-radius: 4px;
  }
  
  .formField input:focus {
    outline: none;
  }
  
  .formField label{
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 3px;
  }
  
  button{
    background-color: rgb(16, 224, 220);
    width: 85%;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    padding: 10px 30px;
    color: white;
    font-size: 10px;
    cursor: pointer;
  }
  
  button:hover{
    background-color: rgb(4, 147, 144);
  }
  
  .errorMsg{
    color: red;
    margin: 0;
    align-self: flex-start;
  }
  .msgOK{
    color: green;
  }

  .newAccountButton{
    width: 100%;
  }
}
/*-----------------------------スマホサイズ-----------------------------------*/
@media (max-width: 600px){

  .formContainer form{
    background-color: white;
    width:100%;
    height: 100%;
  }
  
  .uiForm{
    display: flex;
    flex-direction: column;/*縦並び*/
    align-items: center;
    justify-content: space-evenly;
    padding-top:10%;
    padding-bottom: 25%;
    margin: auto;
    width: 250px;
    height: 300px;
  }
  
  
  
  h1{
    text-align: center;
    font-size: 20px;
  }
  
  .formField {
    display:flex;
    flex-direction: column;
    width: 100%;    
  }
  
  .formField input{
  border: 1px solid gray;
  padding: 15px;
  border-radius: 4px;
  }
  
  .formField input:focus {
    outline: none;
  }
  
  .formField label{
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 3px;
  }
  
  button{
    background-color: rgb(16, 224, 220);
    width: 100%;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    padding: 10px 30px;
    color: white;
    font-size: 10px;
    cursor: pointer;
  }
  
  button:hover{
    background-color: rgb(4, 147, 144);
  }
  
  .errorMsg{
    color: red;
    margin: 0;
    align-self: flex-start;
  }
  .msgOK{
    color: green;
  }
}






/* Menu.jsx */

.app-profiles {
  background-color: #292f38;
  width: 25%;
  min-width: 350px;
  height: 700px;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
}

.sample-box-02 {
    margin: 10px 10px 10px 10px;
    border: 3px double #ddd;
    padding: 2px;
    text-align: center;
}


.app-details {
  text-align: center;
  display: flex;
  width: 11rem;
  padding: 1rem;
  border: 1px solid rgb(30, 30, 30);
  border-radius: 12px;
  
}

.btn1{
  display:inline-block;
}
.btn2{
  display:inline-block;
}
.btn3{
  display:inline-block;
}
.btn4{
  display:inline-block;
}
.btn5{
  display:inline-block;
}
.btn6{
  display:inline-block;
}
button{
  display:inline-block;
}


li{
  list-style:none;/*ドットを消した*/
}
.all{
  
justify-content: center;
}
.MenuButton{
  display: flex;
  padding-left:0;
  justify-content: center;
  align-items: center;
}
.MenuContents{
  order:3;
}
.ProfileBox{
  order:2;
}

/* 
.title-ask {
  margin-right: 30px;
}


.app-details {
  background-color: #292f38;
  width: 15%;
  min-width: 270px;
  height: 305px;
  padding: 10px;
  margin: 10px 0px 30px 45px;
  border-radius: 5px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
} */

.search_component{
  position:relative;
  top:0px;
  height:100%;
  width:100%;
}

.songlist{
  position:relative;
  top:0px;
  width: 100%;
  height:100%;
}

html{
  height:90vh;
}
body{
  height:90vh;
  margin:0px;
}

.scroll{
  position:relative;
  top:0px;
  height: 85vh;
  overflow: auto;
}

#song_list{
  position:relative;
  top: 0px;
}

.songlist-and-search{
  height:1px;
}


.approval_request_list{
  text-align: center;
}