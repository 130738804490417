



  ul.topnav {
    width:100%;
	overflow: hidden;
	margin: 0;
	padding: 0;
	list-style-type: none;
	background-color: #0baab5;
}
ul.topnav li {
	float: left;
    
}
ul.topnav li a {
	display: block;
	padding: 14px 16px;
	text-align: center;
	text-decoration: none;
	color: white;
}
ul.topnav li a:hover:not(.active) {
	background-color: #a9bce2;
}
ul.topnav li a.active {
	background-color: #e4740cfb;
}
ul.topnav li.right {
	float: right;
}
@media screen and (max-width: 480px) {
	ul.topnav li.right, ul.topnav li {
		float: none;
	}
}